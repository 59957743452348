<!-- @format -->

<template>
	<layout-default-new>
		<sub-nav-bar page="Marketing" />
		<div>
			<HeroBox :title="title" :subtitle="subtitle" :color="color" />
		</div>
		<div>
			<PotentialCustomerList />
		</div>
	</layout-default-new>
</template>

<script>
import { ref } from '@vue/composition-api'
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import PotentialCustomerList from '@/_srcv2/pages/business-development/potential-customers/PotentialCustomersList.vue'

export default {
	name: 'Marketing',
	components: {
		SubNavBar,
		HeroBox,
		PotentialCustomerList,
	},
	setup() {
		const title = ref('Marketing Page')
		const subtitle = ref(
			'Potential customers, customer visits, and customer leads',
		)
		const color = ref('is-info')
		return {
			title,
			subtitle,
			color,
		}
	},
}
</script>

<style scoped></style>
